import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Button, TextField, Typography, Container, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { getUsers, createUser, updateUser, deleteUser } from '../services/api';

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [editingUser, setEditingUser] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        try {
            const response = await getUsers();
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to load users:', error);
        }
    };

    const handleCreateUser = async () => {
        try {
            await createUser(newUsername, newPassword);
            setNewUsername('');
            setNewPassword('');
            loadUsers();
        } catch (error) {
            console.error('Failed to create user:', error);
        }
    };

    const handleUpdateUser = async () => {
        try {
            await updateUser(editingUser.id, editingUser.username);
            setIsEditDialogOpen(false);
            loadUsers();
        } catch (error) {
            console.error('Failed to update user:', error);
        }
    };

    const handleDeleteUser = async (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await deleteUser(id);
                loadUsers();
            } catch (error) {
                console.error('Failed to delete user:', error);
            }
        }
    };

    const openEditDialog = (user) => {
        setEditingUser(user);
        setIsEditDialogOpen(true);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                User Management
            </Typography>
            <Button onClick={() => navigate('/statistics')} variant="contained" color="primary">
                Back to Statistics
            </Button>
            <div>
                <TextField
                    label="Username"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <Button onClick={handleCreateUser} variant="contained" color="primary">
                    Create User
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>
                                    <Button onClick={() => openEditDialog(user)} variant="contained" color="primary">
                                        Edit
                                    </Button>
                                    <Button onClick={() => handleDeleteUser(user.id)} variant="contained" color="secondary">
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Username"
                        value={editingUser?.username || ''}
                        onChange={(e) => setEditingUser({...editingUser, username: e.target.value})}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEditDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateUser} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default UserManagement;