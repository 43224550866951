import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Statistics from './components/Statistics';
import NumberDetails from './components/NumberDetails';
import NumberManagement from './components/NumberManagement';
import UserManagement from './components/UserManagement';

function PrivateRoute({ children }) {
    return localStorage.getItem('token') ? children : <Navigate to="/login" />;
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/statistics"
                    element={
                        <PrivateRoute>
                            <Statistics />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/number-details"
                    element={
                        <PrivateRoute>
                            <NumberDetails />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/number-management"
                    element={
                        <PrivateRoute>
                            <NumberManagement />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/user-management"
                    element={
                        <PrivateRoute>
                            <UserManagement />
                        </PrivateRoute>
                    }
                />
                <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    );
}

export default App;