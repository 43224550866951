import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Button, TextField, Typography, Container, Chip
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { getStatistics } from '../services/api';

function Statistics() {
    const [statistics, setStatistics] = useState([]);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();

    useEffect(() => {
        loadStatistics();
    }, []);

    const loadStatistics = async () => {
        try {
            const response = await getStatistics(startDate, endDate);
            const sortedData = response.data.sort((a, b) => b.count - a.count);
            setStatistics(sortedData);
        } catch (error) {
            console.error('Failed to load statistics:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const handleSort = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setStatistics([...statistics].sort((a, b) =>
            newOrder === 'asc' ? a.count - b.count : b.count - a.count
        ));
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Statistics
            </Typography>
            <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <Button onClick={loadStatistics} variant="contained" color="primary">
                Run Query
            </Button>
            <Button onClick={handleLogout} variant="contained" color="secondary">
                Logout
            </Button>
            <Button onClick={() => navigate('/user-management')} variant="contained">
                User Management
            </Button>
            <Button onClick={() => navigate('/number-management')} variant="contained">
                Number Management
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>
                                Count
                                <Button onClick={handleSort}>
                                    {sortOrder === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statistics.map((row) => (
                            <TableRow key={row.number}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    <Button
                                        color="primary"
                                        onClick={() => navigate(`/number-details?number=${row.number}&startDate=${startDate}&endDate=${endDate}`)}
                                    >
                                        {row.number}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {row.tags.map((tag, index) => (
                                        <Chip key={index} label={tag} style={{ margin: '2px' }} />
                                    ))}
                                </TableCell>
                                <TableCell>{row.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default Statistics;