import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Button, TextField, Typography, Container, Chip, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { getNumbers, createNumber, updateNumber, deleteNumber } from '../services/api';

function NumberManagement() {
    const [numbers, setNumbers] = useState([]);
    const [newNumber, setNewNumber] = useState('');
    const [newName, setNewName] = useState('');
    const [newTags, setNewTags] = useState('');
    const [editingNumber, setEditingNumber] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadNumbers();
    }, []);

    const loadNumbers = async () => {
        try {
            const response = await getNumbers();
            setNumbers(response.data);
        } catch (error) {
            console.error('Failed to load numbers:', error);
        }
    };

    const handleCreateNumber = async () => {
        try {
            await createNumber(newNumber, newName, newTags.split(',').map(tag => tag.trim()));
            setNewNumber('');
            setNewName('');
            setNewTags('');
            loadNumbers();
        } catch (error) {
            console.error('Failed to create number:', error);
        }
    };

    const handleUpdateNumber = async () => {
        try {
            await updateNumber(editingNumber.id, editingNumber.number, editingNumber.name, editingNumber.tags);
            setIsEditDialogOpen(false);
            loadNumbers();
        } catch (error) {
            console.error('Failed to update number:', error);
        }
    };

    const handleDeleteNumber = async (id) => {
        if (window.confirm('Are you sure you want to delete this number?')) {
            try {
                await deleteNumber(id);
                loadNumbers();
            } catch (error) {
                console.error('Failed to delete number:', error);
            }
        }
    };

    const openEditDialog = (number) => {
        setEditingNumber(number);
        setIsEditDialogOpen(true);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Number Management
            </Typography>
            <Button onClick={() => navigate('/statistics')} variant="contained" color="primary">
                Back to Statistics
            </Button>
            <div>
                <TextField
                    label="Number"
                    value={newNumber}
                    onChange={(e) => setNewNumber(e.target.value)}
                />
                <TextField
                    label="Name"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
                <TextField
                    label="Tags (comma-separated)"
                    value={newTags}
                    onChange={(e) => setNewTags(e.target.value)}
                />
                <Button onClick={handleCreateNumber} variant="contained" color="primary">
                    Create Number
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {numbers.map((number) => (
                            <TableRow key={number.id}>
                                <TableCell>{number.number}</TableCell>
                                <TableCell>{number.name}</TableCell>
                                <TableCell>
                                    {number.tags.map((tag, index) => (
                                        <Chip key={index} label={tag} style={{ margin: '2px' }} />
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => openEditDialog(number)} variant="contained" color="primary">
                                        Edit
                                    </Button>
                                    <Button onClick={() => handleDeleteNumber(number.id)} variant="contained" color="secondary">
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
                <DialogTitle>Edit Number</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Number"
                        value={editingNumber?.number || ''}
                        onChange={(e) => setEditingNumber({...editingNumber, number: e.target.value})}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Name"
                        value={editingNumber?.name || ''}
                        onChange={(e) => setEditingNumber({...editingNumber, name: e.target.value})}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Tags (comma-separated)"
                        value={editingNumber?.tags.join(', ') || ''}
                        onChange={(e) => setEditingNumber({...editingNumber, tags: e.target.value.split(',').map(tag => tag.trim())})}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEditDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateNumber} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default NumberManagement;