import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Typography, Container, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getNumberDetails } from '../services/api';

function NumberDetails() {
    const [details, setDetails] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const number = searchParams.get('number');
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        loadNumberDetails(number, startDate, endDate);
    }, [location]);

    const loadNumberDetails = async (number, startDate, endDate) => {
        try {
            const response = await getNumberDetails(number, startDate, endDate);
            setDetails(response.data);
        } catch (error) {
            console.error('Failed to load number details:', error);
        }
    };

    if (!details) return <div>Loading...</div>;

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Number Details
            </Typography>
            <Typography variant="h6">Number: {details.numberInfo.number}</Typography>
            <Typography variant="h6">Name: {details.numberInfo.name}</Typography>
            <Typography variant="h6">Total Calls: {details.totalCount}</Typography>
            <Typography variant="h6">Tags: {details.numberInfo.tags.join(', ')}</Typography>
            <Typography variant="h6">Period: {details.startDate} - {details.endDate}</Typography>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={details.dailyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.dailyData.map((day) => (
                            <TableRow key={day.date}>
                                <TableCell>{day.date}</TableCell>
                                <TableCell>{day.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button onClick={() => navigate('/statistics')} variant="contained" color="primary">
                Back to Statistics
            </Button>
        </Container>
    );
}

export default NumberDetails;