import axios from 'axios';

// const API_URL = 'http://localhost:3000/api';
const API_URL = 'https://numstats.6yz.ru/api';

const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const login = (username, password) => api.post('/login', {username, password});
export const getStatistics = (startDate, endDate) => api.post('/query', {periodStart: startDate, periodEnd: endDate});
export const getNumberDetails = (number, startDate, endDate) =>
    api.get(`/number-details?number=${number}&startDate=${startDate}&endDate=${endDate}`);
export const getNumbers = () => api.get('/numbers');
export const createNumber = (number, name, tags) => api.post('/numbers', {number, name, tags});
export const updateNumber = (id, number, name, tags) => api.put(`/numbers/${id}`, {number, name, tags});
export const deleteNumber = (id) => api.delete(`/numbers/${id}`);
export const getUsers = () => api.get('/users');
export const createUser = (username, password) => api.post('/users', {username, password});
export const updateUser = (id, username) => api.put(`/users/${id}`, {username});
export const deleteUser = (id) => api.delete(`/users/${id}`);

export default api;